import React, { useState, useEffect } from "react";
import { Modal, List, Avatar } from "antd";
import "./MultiAgentInteraction.css";
import { useGetAgentConversationsQuery } from "../../services/agentConversations";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const MultiAgentInteraction = ({ isOpen, onClose }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const teamsId = params.get("id") ? params.get("id") : 123;
  const { data } = useGetAgentConversationsQuery(teamsId);
  const selectedConversation = useSelector(
    (state) => state.selectedConversation
  );

  const currentTopic = data?.conversations?.find(
    (item) => item._id === selectedConversation
  );

  const [agentInteractionChat, setAgentInteractionChat] = useState([]);

  useEffect(() => {
    if (currentTopic?.chat_history) {
      const formattedChat = currentTopic.chat_history.map((msg, idx) => ({
        content: msg.content,
        sender: idx % 2 == 0 ? "right" : "left",
        name: msg.name || "Unknown",
      }));
      setAgentInteractionChat(formattedChat);
    }
  }, [isOpen, currentTopic]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      title="Multi-agent interaction"
      width={1000}
      className="multi-agent-interaction"
      destroyOnClose={true}
    >
      <div style={{ overflowY: "auto", padding: "10px" }}>
        <List
          dataSource={agentInteractionChat}
          renderItem={(msg, index) => (
            <List.Item
              key={index}
              style={{
                display: "flex",
                justifyContent:
                  msg.sender === "right" ? "flex-end" : "flex-start",
              }}
            >
              <Avatar
                title={msg.name}
                style={{
                  backgroundColor: "#7367f0",
                  color: "white",
                  textTransform: "uppercase",
                }}
              >
                {msg.name[0]}
                {msg.name[1]}
              </Avatar>
              &nbsp;
              <span
                style={{
                  padding: "8px 12px",
                  borderRadius:
                    msg.sender === "left"
                      ? "0 15px 15px 15px"
                      : "15px 15px 0px 15px",
                  background: msg.sender === "right" ? "#242745" : "#f5f5f5",
                  color: msg.sender === "right" ? "#fff" : "#000",
                  maxWidth: "70%",
                }}
              >
                {msg.content}
              </span>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default MultiAgentInteraction;
