import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "./Components/Navbar/Navbar";
import { loginPath, skipLogin, subDomain } from "./constants/constants";
import HomeScreen from "./Screens/HomeScreen/HomeScreen";
import kfcTheme from "./themes/kfc";
import { applyTheme } from "./themes/utils";
import pizzaTheme from "./themes/pizza";
import Logout from "./Screens/Logout/Logout";
import { updateUserAccess } from "./services/userSlice";
import {
  clearCookies,
  getPrefixedCookieName,
  renameCookiesWithDomain,
  userAccess,
} from "./utils/utils";
import { useGetWorkforcesQuery } from "./services/workforceService";
import Filters from "./Components/Filters/Filters";
import Agents from "./Screens/Agents/Agents";

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function getQueryParams() {
  return new URLSearchParams(window.location.search);
}

function App() {
  useEffect(() => {
    renameCookiesWithDomain();
  }, []);

  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(skipLogin ? true : false);

  const { data, error, isFetching } = useGetWorkforcesQuery("", {
    skip: !isAuth,
  });

  const [isLogout, setisLogout] = useState(
    window.location.pathname === "/logout"
  );
  // const [hideNavbar, setHideNavbar] = useState(false);
  // setTimeout(() => {
  //   console.log("neeraj");
  //   window.mapp = true;
  //   setHideNavbar(true);
  // }, 10000);

  const [showSidebar, setShowSidebar] = useState(true);
  const userAccess = useSelector((state) => state.userAccess);

  useEffect(() => {
    const queryParams = getQueryParams();
    const id = queryParams.get("id");
    const isStdBot = Cookies.get("activeConfigName");
    console.log("🚀 ~ useEffect ~ isStdBot:", isStdBot);

    if (!Cookies.get("stdBotConfig")) {
      Cookies.set("stdBotConfig", "22eff540-7cf5-11ee-8e30-acde48001122", {
        expires: 365,
      });
      Cookies.set("requestBased", "http", { expires: 365 });

      // Cookies.set("requestBased", "http", { expires: 365 });
    }
    if (!Cookies.get("activeConfig")) {
      Cookies.set("activeConfig", "22eff540-7cf5-11ee-8e30-acde48001122", {
        expires: 365,
      });
      Cookies.set("activeConfigName", "Standard Bot", { expires: 365 });
    }
    if (id && id !== null) {
      Cookies.set("requestBased", "socket", { expires: 365 });
    } else if (isStdBot === "Standard Bot") {
      Cookies.set("requestBased", "http", { expires: 365 });
    }
  }, [userAccess.config]);

  const [theme, setTheme] = useState();

  useEffect(() => {
    if (!isAuth && !isLogout) {
      const accessToken = Cookies.get(
        getPrefixedCookieName("crave_jwt_access_token")
      );
      if (!accessToken) {
        clearCookies();
        window.location.href = loginPath;
      } else {
        setIsAuth(true);
      }
    }
  }, [isAuth, isLogout, userAccess]);

  useEffect(() => {
    const setWindowWidth = () => {
      setShowSidebar(window.innerWidth > 1024);
    };

    setWindowWidth();

    window.addEventListener("resize", setWindowWidth);

    return () => {
      window.removeEventListener("resize", setWindowWidth);
    };
  }, []);

  useEffect(() => {
    // if (userAccess.brand) {
    //   const { brand } = userAccess;
    //   if (brand === "kfc") applyTheme(kfcTheme);
    //   else if (brand === "pizzahut") applyTheme(pizzaTheme);
    // }
    applyTheme(kfcTheme);
  }, [userAccess]);

  const getSpaceIdsByWorkforceId = (arr, targetWorkforceId, fieldName) => {
    return arr
      ?.filter((item) => item[fieldName] == targetWorkforceId)
      ?.map((item) => item.space_id);
  };

  const findSpaceIdByVersionId = (arr, workforceVersionId) => {
    for (const obj of arr) {
      if (obj.workforce_versions) {
        for (const version of obj.workforce_versions) {
          if (version.workforce_version_id == workforceVersionId) {
            return obj.space_id;
          }
        }
      }
    }
    return null;
  };

  const getSpaceId = () => {
    const queryParams = getQueryParams();
    let workforceId = queryParams.get("id");

    let spaceId = null;
    if (workforceId != null) {
      spaceId = findSpaceIdByVersionId(data || [], workforceId);
      return spaceId;
    }

    const activeConfigName = Cookies.get("activeConfigName");
    spaceId = getSpaceIdsByWorkforceId(data, activeConfigName, "title");
    return spaceId?.[0];
  };

  const spaceId = getSpaceId();

  return (
    <>
      {isAuth && !isLogout && (
        <Router basename={subDomain}>
          <div className="flex flex-col max-h-screen">
            {!window.mapp && spaceId && <Filters spaceId={spaceId} />}
          </div>
          <div className="flex flex-col max-h-screen">
            {!window.mapp && (
              <Navbar
                menuOpen={showSidebar}
                setMenuOpen={setShowSidebar}
                workforceData={data}
                isFetching={isFetching}
              />
            )}

            <Routes>
              <Route path="/logout" key={Math.random()} element={<Logout />} />
              <Route path="/agents" key={Math.random()} element={<Agents />} />
              <Route path="/teams" key={Math.random()} element={<Agents />} />
              <Route
                exact
                path="/"
                key={Math.random()}
                element={
                  <HomeScreen
                    showSidebar={showSidebar}
                    setShowSidebar={setShowSidebar}
                  />
                }
              />
            </Routes>
            {/* </div> */}
          </div>
        </Router>
      )}
      {isLogout && (
        <Router basename={subDomain}>
          <Routes>
            <Route path="/logout" key={Math.random()} element={<Logout />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
